<template>
  <v-layout style="overflow: hidden; overflow-x: auto" class="pb-3">
    <v-flex v-for="(item, i) in filteredRecommendations" :key="i">
      <v-lazy transition="fade-transition">
        <just-for-you-card
          class="mr-4"
          :item="item"
          :width="$vuetify.breakpoint.mdAndUp ? '25vw' : '75vw'"
        ></just-for-you-card>
      </v-lazy>
    </v-flex>
  </v-layout>
</template>

<script>
import JustForYouCard from "@/components/recommendations/JustForYouCard";
import find from "lodash/find";
import indexOf from "lodash/indexOf";
export default {
  components: {
    JustForYouCard,
  },
  props: ["filteredRecommendations"],
  methods: {
    toggle(id) {
      if (
        !find(this.$store.state.profile.data.favorites, (fav) => {
          return fav === id;
        })
      ) {
        if (!this.$store.state.profile.data.favorites) {
          this.$store.state.profile.data.favorites = [];
        }
        this.$store.state.profile.data.favorites.push(id);
      } else {
        this.$store.state.profile.data.favorites.splice(
          indexOf(this.$store.state.profile.data.favorites, id),
          1
        );
      }

      this.$store.dispatch(
        "profile/updateProfile",
        {
          profile: this.$store.state.profile.data,
        },
        { root: true }
      );
    },
    find,
  },
};
</script>
