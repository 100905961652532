<template>
  <v-dialog fullscreen v-model="addressPicker">
    <template v-slot:activator="{ on }" v-if="$route.name != 'home'">
      <v-text-field
        class="advancedSearch"
        :class="$store.state.settings.dark ? 'whitePlaceholder' : ''"
        :style="$store.state.settings.dark ? 'background-color: #272727;' : ''"
        solo
        dense
        hide-details
        v-model="address"
        :placeholder="$t('search.addAdress')"
        readonly
        v-on="on"
      >
        <template v-slot:append>
          <img
            width="24"
            height="24"
            :src="require('@/assets/search/location.svg')"
            :class="$store.state.settings.dark ? 'filter' : ''"
          />
        </template>
      </v-text-field>
    </template>
    <v-card class="pa-0" flat height="100%">
      <back-toolbar
        :closeBar="true"
        @close="addressPicker = false"
        :title="$t('search.addAdress')"
      ></back-toolbar>
      <v-row justify="center" dense class="px-4 pt-16" align="center">
        <v-col cols="12">
          <v-toolbar
            height="38px"
            style="border-radius: 10px"
            class="select-on-map"
          >
            <v-menu offset-y content-class="location-menu">
              <template v-slot:activator="{ on }">
                <v-autocomplete
                  solo
                  dense
                  flat
                  hide-details
                  :label="$t('search.yourAddress')"
                  @click="showMap = false"
                  v-model="search"
                  :items="locationEntries"
                  :search-input.sync="locationSearchText"
                  item-text="value"
                  item-value="id"
                  hide-no-data
                  return-object
                  clearable
                  v-on="on"
                  prepend-icon="mdi-magnify"
                  append-icon=""
                  :class="$store.state.settings.dark ? 'dark' : 'light'"
                >
                </v-autocomplete>
              </template>
            </v-menu>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-container class="pt-0">
        <v-list v-if="!locationSearchText && !showMap">
          <v-list-item dense class="px-0" @click="resetManualLocation()">
            <v-list-item-avatar size="25" class="mr-1">
              <img
                :src="require('@/assets/search/location.svg')"
                :class="$store.state.settings.dark ? 'filter' : ''"
              />
            </v-list-item-avatar>
            <v-list-item-title class="Heading-Dark-H5-16-Left-Align">{{
              $t("search.currentPosition")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item dense class="px-0" @click="showMap = true">
            <v-list-item-avatar size="25" class="mr-1">
              <img
                :src="require('@/assets/search/chooseOnMap.svg')"
                :class="$store.state.settings.dark ? 'filter' : ''"
              />
            </v-list-item-avatar>
            <v-list-item-title class="Heading-Dark-H5-16-Left-Align">{{
              $t("search.chooseOnMap")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item
            v-for="(item, index) in locationEntries"
            :key="index"
            v-on="
              $route.name === 'home'
                ? { click: () => setLocation(item) }
                : { click: () => setAddress(item.value) }
            "
          >
            <v-list-item-title>{{ item.value }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-row class="py-0">
          <v-col cols="12" align="right">
            <v-img
              width="100px"
              contain
              :src="require('@/assets/search/powered_by_google_on_white.png')"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="showMap">
        <v-container class="py-0">
          <v-row>
            <v-col class="pt-0">
              <div class="Text-Dark-Text-2---16-Left-Align">
                {{ $t("search.tapMap") }}
              </div>
            </v-col>
          </v-row>
        </v-container>

        <l-map
          ref="map"
          style="height: 52%; z-index: 0; position: relative"
          :zoom="zoom"
          :options="{ zoomControl: true }"
          :center="center"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
          @click="handleMapClick"
        >
          <l-tile-layer :url="url"></l-tile-layer>
          <!--<v-tilelayer-googlemutant
            :apikey="apiKey"
            lang="de"
            region="AT"
            :options="options"
          ></v-tilelayer-googlemutant>-->
          <l-marker :lat-lng="selectedLocation">
            <l-icon
              :icon-size="[50, 50]"
              :icon-anchor="[13.5, 47.25]"
              :icon-url="
                require('@/assets/googlemaps_markers/address_picker.svg')
              "
            ></l-icon>
          </l-marker>

          <!-- User position -->
          <l-marker :lat-lng="userPosition">
            <l-icon
              :icon-size="[20, 20]"
              :icon-anchor="[10, 10]"
              :icon-url="
                require('@/assets/googlemaps_markers/userPositionCircle.svg')
              "
            ></l-icon>
          </l-marker>
        </l-map>
        <v-card class="py-4" flat>
          <v-card-actions>
            <v-btn
              depressed
              height="48"
              width="315"
              color="primary"
              class="gradient Heading-White-H4-18-Center d-block mx-auto"
              @click="setManualLocation(selectedLocation)"
              >{{ $t("search.setLocation") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import { LMap, LMarker, LIcon, LTileLayer } from "vue2-leaflet";
//import Vue2LeafletGoogleMutant from "vue2-leaflet-googlemutant";
import { mapState, mapMutations } from "vuex";
import { GetSuggestions } from "@/utils/PlaceUtils";

export default {
  data() {
    return {
      showMap: false,
      search: "",
      apiKey: "AIzaSyDg1Dztn_OVruFwNPvUPWG2p3OGBBVRp2M",
      options: {
        type: "roadmap",
      },
      value: "km",
      // selectedLocation needs these default values in order to work properly
      selectedLocation: {
        lat: this.$store.state.map.userPosition.lat,
        lng: this.$store.state.map.userPosition.lng,
      },
      locationSearchText: null,
      locationEntries: [],
      placeId: null,
      url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
    };
  },
  components: {
    BackToolbar,
    LMap,
    LMarker,
    LTileLayer,
    LIcon,
    //"v-tilelayer-googlemutant": Vue2LeafletGoogleMutant,
  },
  computed: {
    ...mapState({
      mapCenter: (state) => state.map.center,
      userPosition: (state) => state.map.userPosition,
    }),
    center: {
      get() {
        return this.$store.state.map.userPosition;
      },
      set(val) {
        this.setCenter(val);
      },
    },
    zoom: {
      get() {
        return this.$store.state.map.zoom;
      },
      set(val) {
        this.$store.commit("map/SET_ZOOM", val);
      },
    },
    locationFoundItems() {
      return this.locationEntries;
    },
    addressPicker: {
      get() {
        return this.$store.state.dashboard.openAdresspicker;
      },
      set(val) {
        this.$store.commit("dashboard/SET_ADDRESS_PICKER", val);
      },
    },
    address: {
      get() {
        return this.$store.state.session.address;
      },
      set(val) {
        this.$store.commit("session/SET_ADDRESS", val);
      },
    },
  },
  methods: {
    // Setting the location back to the original (gps based) location
    resetManualLocation() {
      this.$store.commit("recommendations/SET_MANUAL_LOCATION", false);
      this.$store.dispatch("session/setUserPosition", {
        lat: this.$store.state.map.originalPosition.lat,
        lng: this.$store.state.map.originalPosition.lng,
      });
      this.addressPicker = false;
    },
    // This function is called when the user searches for a location and selects a search result
    async setLocation(item) {
      let location = {};
      const geocoder = new google.maps.Geocoder();
      // Turns the place id into latitude and longitude
      await geocoder.geocode({ placeId: item.id }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            location.lat = results[0].geometry.location.lat();
            location.lng = results[0].geometry.location.lng();
          }
        } else {
          console.error("Failed to get Lat and Long from place Id");
        }
      });

      this.setManualLocation(location);
    },
    // Setting the location manually and disabling automatic location
    setManualLocation({ lat, lng }) {
      this.$store.commit("recommendations/SET_MANUAL_LOCATION", true);
      this.$store.dispatch("session/setUserPosition", {
        lat: lat,
        lng: lng,
      });
      this.addressPicker = false;
    },
    // Used in advanced Search
    setAddress(item) {
      this.$store.commit("session/SET_ADDRESS", item);
      this.addressPicker = false;
    },
    handleMapClick(event) {
      this.selectedLocation = {
        lat: event.latlng.lat,
        lng: event.latlng.lng,
      };
    },
    reset() {
      this.address = "";
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    centerUpdated(center) {
      this.center = center;
    },
    ...mapMutations({
      setCenter: "map/SET_CENTER",
    }),
  },
  watch: {
    addressPicker(val) {
      // Resetting the address picker when it gets closed.
      if (!val) {
        this.showMap = false;
        this.selectedLocation = {
          lat: this.$store.state.map.userPosition.lat,
          lng: this.$store.state.map.userPosition.lng,
        };
        this.locationSearchText = null;
        this.locationEntries = [];
        this.placeId = null;
      }
    },
    locationSearchText(newVal) {
      var _this = this;
      // Search only after at least 1 character has been typed to prevent error in console
      if (!newVal || newVal.length < 1) return;
      if (newVal.length > 1) {
        GetSuggestions(newVal)
          .then(function (response) {
            // if condition is necessary to prevent a null value in the dropdown menu
            if (response) {
              _this.locationEntries = response;
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.location-menu {
  background-color: transparent;
  box-shadow: none !important;
}

.v-list-item {
  height: 32px;
}

/* Highlight selected divIcon */
.highlight-pin {
  z-index: 1000 !important;
}
.center-location {
  width: 32px;
  height: 35px;
  left: 1px;
  line-height: 30px;
  background: #fff;
  border: 2px solid #aaa;
  border-radius: 4px;
  text-align: center;
}
p {
  span {
    color: inherit !important;
    background-color: transparent !important;
  }
}
.v-card {
  border-radius: 0px !important;
}
.v-input--checkbox .v-label {
  font-size: 12px;
}
.v-input--checkbox .v-messages {
  display: none;
}
.select-on-map .v-input__prepend-outer {
  margin-right: 0;
}
.select-on-map .v-input__control .v-input__slot {
  padding: 4px !important;
}
.select-on-map .v-toolbar__content {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.v-menu__content.v-autcomplete__content.theme--light .v-list {
  background-color: #fff !important;
}
.v-menu__content.theme--dark .v-list {
  background-color: #1e1e1e !important;
}
.v-menu__content.v-autocomplete__content .v-list.v-select-list {
  display: none !important;
}
.v-menu__content.location-menu.theme--light * {
  background-color: #fff !important;
}
.v-menu__content.location-menu.theme--dark * {
  background-color: #1e1e1e !important;
}
</style>
