<template>
  <v-container class="pt-0">
    <div v-if="!$vuetify.breakpoint.lgAndUp">
      <v-btn
        v-if="isMiKY"
        fixed
        top
        left
        elevation="3"
        fab
        icon
        style="background-color: var(--v-background-base)"
        @click="$store.commit('SET_MENU', true)"
      >
        <img
          class="color-filter"
          :src="require('@/assets/navigation/burger_icon.png')"
          width="30"
          height="30"
        />
      </v-btn>
    </div>
    <div v-if="isMiKY" style="height: 64px" />
    <greeting></greeting>
    <search-app-bar></search-app-bar>
    <template>
      <template>
        <v-row align="center" justify="center" class="mt-4">
          <h2
            class="ml-4 HeadingDarkH3-22Left-Align"
            v-if="
              (!$store.state.session.user && !$store.state.isMiKY) ||
              (filteredJustForYou && filteredJustForYou.length) ||
              loading
            "
          >
            {{ $t("dashboard.justForYou") }}
          </h2>
          <v-spacer></v-spacer
          ><v-btn
            v-if="
              ($store.state.session.user || $store.state.isMiKY) &&
              filteredJustForYou.length
            "
            class="Text-Color-Text-3---14-Left-Align"
            text
            color="primary"
            @click="
              $router.safePush({
                name: 'allRecommendations',
                query: { ...$router.currentRoute.query, type: 'justForYou' },
              })
            "
            >{{ $t("dashboard.seeAll") }}</v-btn
          ></v-row
        >
        <v-skeleton-loader
          v-if="loading && ($store.state.session.user || $store.state.isMiKY)"
          width="270"
          height="200"
          style="border-radius: 8px"
          type="image"
        >
        </v-skeleton-loader>
        <v-lazy v-if="!$store.state.session.user && !$store.state.isMiKY">
          <image-slider />
        </v-lazy>
        <v-lazy
          v-if="!loading && ($store.state.session.user || $store.state.isMiKY)"
          transition="fade-transition"
        >
          <recommendation-slider
            v-if="filteredJustForYou.length"
            :filteredRecommendations="filteredJustForYou"
          ></recommendation-slider>
        </v-lazy>
      </template>
      <template v-if="filteredCategories && filteredCategories.length">
        <v-row align="center" justify="center" class="mt-2">
          <h2 class="ml-4 HeadingDarkH3-22Left-Align">
            {{ $t("dashboard.categories") }}
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            class="Text-Color-Text-3---14-Left-Align"
            text
            color="primary"
            @click="
              $router.safePush({
                name: 'allCategories',
                query: { ...$router.currentRoute.query, type: 'category' },
              })
            "
            >{{ $t("dashboard.seeAll") }}</v-btn
          >
        </v-row>
        <filter-slider
          :items="filteredCategories"
          type="category"
        ></filter-slider>
      </template>
      <template v-if="filteredHighlights && filteredHighlights.length">
        <v-row align="center" justify="center" class="mt-2">
          <h2 class="ml-4 HeadingDarkH3-22Left-Align">
            {{ $t("dashboard.highlights") }}
          </h2>
          <v-spacer></v-spacer
          ><v-btn
            class="Text-Color-Text-3---14-Left-Align"
            text
            color="primary"
            @click="
              $router.safePush({
                name: 'allRecommendations',
                query: { ...$router.currentRoute.query, type: 'highlights' },
              })
            "
            >{{ $t("dashboard.seeAll") }}</v-btn
          ></v-row
        >
        <v-lazy transition="fade-transition">
          <item-slider
            :filteredRecommendations="filteredHighlights"
          ></item-slider>
        </v-lazy>
      </template>
      <template>
        <div>
          <div
            v-if="
              goLocalItems && goLocalItems.items && goLocalItems.items.length
            "
          >
            <v-row align="center" justify="center" class="mt-2">
              <h2 class="ml-4 HeadingDarkH3-22Left-Align">
                {{ $t("dashboard.go local") }}
              </h2>
              <v-spacer></v-spacer
              ><v-btn
                class="Text-Color-Text-3---14-Left-Align"
                text
                color="primary"
                @click="
                  $router.safePush({
                    name: 'allRecommendations',
                    query: {
                      ...$router.currentRoute.query,
                      type: 'byTag',
                      typeDetail: 'go local',
                    },
                  })
                "
                >{{ $t("dashboard.seeAll") }}</v-btn
              ></v-row
            >
            <v-skeleton-loader
              v-if="goLocalLoading"
              width="270"
              height="150"
              style="border-radius: 13px"
              type="image"
            >
            </v-skeleton-loader>
            <v-card
              v-if="goLocalLoading"
              elevation="6"
              :min-height="$vuetify.breakpoint.xs ? '100' : '120'"
              :width="$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'"
              style="border-radius: 15px !important; left: 2.5vw !important"
              :style="$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''"
              class="mt-n8 pt-1"
            >
              <v-skeleton-loader width="234" type="list-item-avatar-two-line" />
            </v-card>
            <v-lazy
              v-if="!goLocalLoading"
              transition="fade-transition"
              style="margin-bottom: env(safe-area-inset-bottom)"
            >
              <item-slider
                :filteredRecommendations="goLocalItems.items.slice(0, 10)"
              ></item-slider>
            </v-lazy>
          </div>
        </div>
      </template>
      <template
        v-if="
          (filteredRecommendations && filteredRecommendations.length) || loading
        "
      >
        <v-row align="center" justify="center" class="mt-2">
          <h2 class="ml-4 HeadingDarkH3-22Left-Align">
            {{ $t("dashboard.recommendations") }}
          </h2>
          <v-spacer></v-spacer
          ><v-btn
            class="Text-Color-Text-3---14-Left-Align"
            text
            color="primary"
            @click="
              $router.safePush({
                name: 'allRecommendations',
                query: {
                  ...$router.currentRoute.query,
                  type: 'recommendations',
                },
              })
            "
            >{{ $t("dashboard.seeAll") }}</v-btn
          ></v-row
        >
        <v-skeleton-loader
          v-if="loading"
          width="270"
          height="150"
          style="border-radius: 13px"
          type="image"
        >
        </v-skeleton-loader>
        <v-card
          v-if="loading"
          elevation="6"
          :min-height="$vuetify.breakpoint.xs ? '100' : '120'"
          :width="$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'"
          style="border-radius: 15px !important; left: 2.5vw !important"
          :style="$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''"
          class="mt-n8 pt-1"
        >
          <v-skeleton-loader width="234" type="list-item-avatar-two-line" />
        </v-card>
        <v-lazy v-if="!loading" transition="fade-transition">
          <item-slider
            :filteredRecommendations="filteredRecommendations"
          ></item-slider>
        </v-lazy>
      </template>
      <template v-if="filteredPopular && filteredPopular.length > 0 && !isMiKY">
        <v-row align="center" justify="center" class="mt-2">
          <h2 class="ml-4 HeadingDarkH3-22Left-Align">
            {{ $t("dashboard.popular") }}
          </h2>
          <v-spacer></v-spacer
          ><v-btn
            class="Text-Color-Text-3---14-Left-Align"
            text
            color="primary"
            @click="
              $router.safePush({
                name: 'allRecommendations',
                query: { ...$router.currentRoute.query, type: 'popular' },
              })
            "
            >{{ $t("dashboard.seeAll") }}</v-btn
          ></v-row
        >
        <v-skeleton-loader
          v-if="loading"
          width="270"
          height="150"
          style="border-radius: 13px"
          type="image"
        >
        </v-skeleton-loader>
        <v-card
          v-if="loading"
          elevation="6"
          :min-height="$vuetify.breakpoint.xs ? '100' : '120'"
          :width="$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'"
          style="border-radius: 15px !important; left: 2.5vw !important"
          :style="$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''"
          class="mt-n8 pt-1"
        >
          <v-skeleton-loader width="234" type="list-item-avatar-two-line" />
        </v-card>
        <v-lazy
          v-if="!loading"
          transition="fade-transition"
          style="margin-bottom: env(safe-area-inset-bottom)"
        >
          <item-slider :filteredRecommendations="filteredPopular"></item-slider>
        </v-lazy>
      </template>
      <template v-if="filteredAttractions && filteredAttractions.length">
        <v-row align="center" justify="center" class="mt-2">
          <h2 class="ml-4 HeadingDarkH3-22Left-Align">
            {{ $t("dashboard.attractions") }}
          </h2>
          <v-spacer></v-spacer
          ><v-btn
            class="Text-Color-Text-3---14-Left-Align"
            text
            color="primary"
            @click="
              $router.safePush({
                name: 'allRecommendations',
                query: { ...$router.currentRoute.query, type: 'attractions' },
              })
            "
            >{{ $t("dashboard.seeAll") }}</v-btn
          ></v-row
        >
        <v-skeleton-loader
          v-if="loading"
          width="270"
          height="150"
          style="border-radius: 13px"
          type="image"
        >
        </v-skeleton-loader>
        <v-card
          v-if="loading"
          elevation="6"
          :min-height="$vuetify.breakpoint.xs ? '100' : '120'"
          :width="$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'"
          style="border-radius: 15px !important; left: 2.5vw !important"
          :style="$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''"
          class="mt-n8 pt-1"
        >
          <v-skeleton-loader width="234" type="list-item-avatar-two-line" />
        </v-card>
        <v-lazy
          v-if="!loading"
          transition="fade-transition"
          style="margin-bottom: env(safe-area-inset-bottom)"
        >
          <item-slider
            :filteredRecommendations="filteredAttractions"
          ></item-slider>
        </v-lazy>
      </template>
      <template v-for="(tag, index) in tags">
        <div :key="'tag-' + index">
          <div
            v-if="
              tagItems[index] &&
              tagItems[index].items &&
              tagItems[index].items.length > 0
            "
          >
            <v-row align="center" justify="center" class="mt-2">
              <h2 class="ml-4 HeadingDarkH3-22Left-Align">
                {{ $t("dashboard." + tag[0]) }}
              </h2>
              <v-spacer></v-spacer
              ><v-btn
                class="Text-Color-Text-3---14-Left-Align"
                text
                color="primary"
                @click="
                  $router.safePush({
                    name: 'allRecommendations',
                    query: {
                      ...$router.currentRoute.query,
                      type: 'byTag',
                      typeDetail: tag,
                    },
                  })
                "
                >{{ $t("dashboard.seeAll") }}</v-btn
              ></v-row
            >
            <v-skeleton-loader
              v-if="tagLoading[index]"
              width="270"
              height="150"
              style="border-radius: 13px"
              type="image"
            >
            </v-skeleton-loader>
            <v-card
              v-if="tagLoading[index]"
              elevation="6"
              :min-height="$vuetify.breakpoint.xs ? '100' : '120'"
              :width="$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'"
              style="border-radius: 15px !important; left: 2.5vw !important"
              :style="$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''"
              class="mt-n8 pt-1"
            >
              <v-skeleton-loader width="234" type="list-item-avatar-two-line" />
            </v-card>
            <v-lazy
              v-if="!tagLoading[index]"
              transition="fade-transition"
              style="margin-bottom: env(safe-area-inset-bottom)"
            >
              <item-slider
                :filteredRecommendations="tagItems[index].items.slice(0, 10)"
              ></item-slider>
            </v-lazy>
          </div>
        </div>
      </template>
      <v-bottom-sheet v-model="addressPicker">
        <v-sheet class="text-center">
          <address-picker />
        </v-sheet>
      </v-bottom-sheet>
    </template>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Greeting from "@/components/dashboard/Greeting";
import SearchAppBar from "@/components/app/SearchAppBar";
import RecommendationSlider from "@/components/dashboard/RecommendationSlider";
import ItemSlider from "@/components/dashboard/ItemSlider";
import FilterSlider from "@/components/dashboard/FilterSlider";
import ImageSlider from "@/components/dashboard/ImageSlider";
import AddressPicker from "@/components/search/AddressPicker";
import shuffle from "lodash/shuffle";
import differenceBy from "lodash/differenceBy";
import find from "lodash/find";

export default {
  components: {
    Greeting,
    SearchAppBar,
    RecommendationSlider,
    ItemSlider,
    FilterSlider,
    ImageSlider,
    AddressPicker,
  },
  data() {
    return {
      tags: [
        ["fine dining"],
        ["hidden gems", "hidden gem"],
        ["specialty coffee"],
        ["shisha lounges"],
        ["brunches"],
        ["curated experiences", "curated experience"],
      ],
    };
  },
  computed: {
    ...mapState({
      categories: ({ categories }) => categories.categories,
      addressPicker: ({ dashboard }) => dashboard.openAdresspicker,
      moods: ({ moods }) => moods.moods,
      isMiKY: (state) => state.isMiKY,
      likes: ({ profile }) => profile.data?.likes,
      loadingStatus: ({ recommendations }) =>
        recommendations.dashboardRecommendationsV4Status,
      justforyouByMiKY: ({ partners }) =>
        partners.config?.filter_settings?.recommendations,
      byTag: ({ recommendations }) => recommendations.byTagV4,
      byTagStatus: ({ recommendations }) => recommendations.byTagV4Status,
    }),
    filteredJustForYou() {
      if (this.isMiKY) {
        return shuffle(this.justforyouByMiKY).slice(0, 10);
      }

      let items = this.$store.getters[
        "recommendations/dashboardRecommendations"
      ];
      const categories = !this.likes?.length
        ? [{ title: "Dining" }]
        : this.likes;
      items = items.filter((item) =>
        categories.find((c) => c.title === item.category?.title)
      );
      items = shuffle(items);
      return items.slice(0, 10);
    },
    filteredAttractions() {
      // Disabling "Attractions" outside of MiKY
      // if (!this.isMiKY) {
      //   return [];
      // }

      let items = this.$store.getters[
        "recommendations/dashboardRecommendations"
      ];
      items = items.filter((item) => item.category?.title === "Attractions");
      items = differenceBy(items, this.filteredJustForYou, "id");
      items = shuffle(items);
      return items.slice(0, 10);
    },
    filteredHighlights() {
      // Disabling "Popular" in MiKY
      // if (this.isMiKY) {
      //   return [];
      // }

      let items = this.$store.getters[
        "recommendations/dashboardRecommendations"
      ];
      items = items.filter((item) => item.highlight);
      items = differenceBy(items, this.filteredJustForYou, "id");
      items = differenceBy(items, this.filteredAttractions, "id");
      items = shuffle(items);
      return items.slice(0, 10);
    },
    filteredPopular() {
      // Disabling "Popular" in MiKY
      // if (this.isMiKY) {
      //   return [];
      // }

      let items = this.$store.getters[
        "recommendations/dashboardRecommendations"
      ];
      items = items.filter((item) => item.clicked > 0);
      items = differenceBy(items, this.filteredJustForYou, "id");
      items = differenceBy(items, this.filteredAttractions, "id");
      items = differenceBy(items, this.filteredHighlights, "id");
      items = shuffle(items);
      return items.slice(0, 10);
    },
    filteredRecommendations() {
      let items = this.$store.getters[
        "recommendations/dashboardRecommendations"
      ];
      items = differenceBy(items, this.filteredJustForYou, "id");
      items = differenceBy(items, this.filteredAttractions, "id");
      items = differenceBy(items, this.filteredHighlights, "id");
      items = differenceBy(items, this.filteredPopular, "id");
      items = shuffle(items);
      return items.slice(0, 10);
    },
    ...mapGetters("categories", ["filteredCategories"]),
    loading() {
      return this.loadingStatus === "fetching" || this.loadingStatus === "";
    },
    tagLoading() {
      let loading = [];
      for (let i = 0; i < this.tags.length; i++) {
        loading.push(
          find(this.byTagStatus, ["tags", this.tags[i]]) === "fetching"
        );
      }
      return loading;
    },
    tagItems() {
      let items = [];
      for (let i = 0; i < this.tags.length; i++) {
        items.push(find(this.byTag, ["tags", this.tags[i]]));
      }
      return items;
    },
    goLocalLoading() {
      return find(this.byTagStatus, ["tags", ["go local"]]) === "fetchin";
    },
    goLocalItems() {
      return find(this.byTag, ["tags", ["go local"]]);
    },
  },
  methods: {
    ...mapActions(["redirectTo"]),
    ...mapMutations({
      setMarker: "map/SET_MARKER",
      setCenter: "map/SET_CENTER",
    }),
  },
};
</script>

<style lang="scss">
.new-overlay {
  position: absolute;
  color: white;
  top: 0;
  font-size: medium;
  padding: 2px 7px;
  z-index: 4;
  right: 0;
}
.chip-list::-webkit-scrollbar {
  display: none;
}
.v-overlay__content {
  width: 100%;
}
.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent;
}
</style>
