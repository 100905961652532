<template>
  <v-container class="pa-0" v-if="item">
    <v-card
      :img="
        item.thumbnail && item.thumbnail.url ? item.thumbnail.url : highResImage
      "
      :height="$vuetify.breakpoint.xs ? '150' : '200'"
      :width="$vuetify.breakpoint.mdAndUp ? '35vw' : '75vw'"
      style="border-radius: 20px !important"
      @click.stop="
        carousel
          ? $emit('carousel-clicked')
          : $router.safePush({
              name: 'detail',
              params: {
                id: item.id,
              },
            })
      "
    >
      <v-card-actions>
        <div
          v-if="item.specialDeals && !item.new && !item.insider"
          class="card-promo white--text"
        >
          {{ $t("detail.specialDeals") }}
        </div>
        <div
          v-if="item.new && !item.insider"
          style="position: absolute; top: 0px; left: 16px"
        >
          <v-img :src="require('@/assets/flag.png')" />
          <span class="flag-content-new Text-White-Text-4---12-Left-Align">{{
            $t("dashboard.new")
          }}</span>
        </div>
        <div
          v-if="item.insider"
          style="position: absolute; top: 0px; left: 16px"
        >
          <v-img :src="require('@/assets/flag.png')" />
          <span
            class="Text-White-Text-4---12-Left-Align flag-content-insider"
            >{{ $t("dashboard.insider") }}</span
          >
        </div>
        <v-spacer></v-spacer>
        <template v-if="item.userRating && item.userRating > 0">
          <v-icon color="yellow darken-2"> mdi-star </v-icon>

          <span class="caption white--text">{{
            parseFloat(item.userRating).toFixed(1)
          }}</span>
        </template>

        <v-btn
          v-if="
            $store.state.profile.data &&
            $store.state.session.user &&
            !$store.state.isMiKY
          "
          icon
          large
          :class="$store.state.settings.dark ? '' : 'white--text'"
          @click.stop="toggleFavorite(item.id)"
          ><v-icon
            :color="
              find($store.state.profile.data.favorites, (fav) => {
                return fav === item.id;
              })
                ? 'red'
                : 'white'
            "
            >{{
              find($store.state.profile.data.favorites, (fav) => {
                return fav === item.id;
              })
                ? "mdi-heart"
                : "mdi-heart-outline"
            }}</v-icon
          >
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      elevation="6"
      :min-height="$vuetify.breakpoint.xs ? '100' : '120'"
      :width="$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'"
      style="border-radius: 15px !important; left: 2.5vw !important"
      :style="$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''"
      class="mt-n8"
      @click.stop="
        carousel
          ? $emit('carousel-clicked')
          : $router.safePush({
              name: 'detail',
              params: {
                id: item.id,
              },
            })
      "
    >
      <v-icon
        v-if="item.weatherSuitability"
        icon
        color="yellow darken-2"
        style="width: 100%; position: absolute"
        class="mt-2"
        >mdi-white-balance-sunny</v-icon
      >
      <v-card-actions v-if="item.category">
        <template v-if="item.category.pictogram">
          <div class="mr-2 gradient pa-2 rounded-circle">
            <v-img
              class="filter"
              width="20px"
              height="auto"
              :src="item.category.pictogram"
            />
          </div>
        </template>
        <span
          :class="!item.category.pictogram ? 'ml-2' : ''"
          class="mt-1 Text-Dark-Text-3---14-Left-Align"
        >
          {{ $t(`categories.${item.category.title}`) }}
        </span>

        <v-spacer></v-spacer>
        <div v-if="item.priceSegment" class="mb-0" style="font-size: 14px">
          {{ item.priceSegment }}
        </div>
      </v-card-actions>
      <v-card-title
        :class="!item.category ? 'pt-4' : ''"
        class="py-0"
        v-if="item.header"
        ><div class="header Heading-Dark-H4-18-Left-Align">
          {{ item.header }}
        </div></v-card-title
      >
      <v-card-text
        class="Text-Dark-Text-3---14-Left-Align pb-2"
        style="word-break: break-word"
      >
        <div class="subHeader">
          {{ item.subHeader }}
        </div>

        <div
          v-if="openingHours"
          v-html="openingHours"
          class="v-html Text-Dark-Text-4---12-Left-Align mt-2"
          :class="$store.state.settings.dark ? 'white--text' : 'black--text'"
        ></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import find from "lodash/find";
import { mapActions } from "vuex";
import api from "@/api";

export default {
  data() {
    return {
      priceIndicator: "$$$$",
    };
  },
  props: {
    item: Object,
    default: () => {
      return {};
    },
    carousel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    openingHours() {
      return api.getOpeningHours(this.item);
    },
    highResImage() {
      let imageUrl = this.item.affiliateImage;

      if (!imageUrl) {
        return require("../../assets/image_placeholder_small.png");
      }

      if (this.item.source === "reserve-out") {
        let imageSource = imageUrl.slice(0, 49);
        let imageName = imageUrl.split("/")[imageUrl.split("/").length - 1];
        let highResUrl = imageUrl;
        if (
          imageSource === "https://ro-images-reserveout.netdna-ssl.com/crop/"
        ) {
          highResUrl = imageSource + "1404x936/" + imageName;
        }
        return highResUrl;
      } else if (this.item.source === "getyourguide") {
        let imageName = imageUrl.substr(imageUrl.length - 7);
        let highResUrl = imageUrl;
        if (imageName === "131.jpg") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 7) + "107.jpg";
        }
        return highResUrl;
      } else if (this.item.source === "musement") {
        let imageWidth = imageUrl.substr(imageUrl.length - 5);
        let highResUrl = imageUrl;
        if (imageWidth === "w=540") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 5) + "w=1404";
        }
        return highResUrl;
      } else {
        return imageUrl;
      }
    },
  },
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
  },
};
</script>

<style scoped>
.v-btn:focus:before,
.v-btn:hover:before {
  opacity: 0 !important;
}
.small-text {
  font-size: 0.7em;
}
.subHeader,
.header {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flag-content-new {
  position: absolute;
  bottom: 0;
  left: 35px;
  top: 3px;
  text-transform: uppercase;
}
.flag-content-insider {
  position: absolute;
  top: 0;
  text-transform: uppercase;
  left: 15px;
  top: 3px;
}
.card-promo {
  background-color: #ea9c06;
  font-family: Hind;
  font-size: 11px;
  text-transform: uppercase;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding: 1px 6px;
  margin: 8px 0 0 23px;
}
</style>
