<template>
  <v-row justify="center" class="px-2" align="center">
    <v-toolbar
      height="38px"
      style="border-radius: 10px"
      width="85%"
      class="match-background-color d-inline-block"
    >
      <v-text-field
        class="mainSearchbar"
        flat
        solo
        hide-details
        dense
        :placeholder="$t('greeting.upToday')"
        :readonly="true"
        prepend-icon="mdi-magnify"
        @click="goToSearch"
        clearable
      ></v-text-field>
    </v-toolbar>
    <v-btn
      v-if="!$store.state.isMiKY"
      @click="$store.commit('dashboard/SET_ADDRESS_PICKER', true)"
      class="match-background-color d-inline-block ml-2"
      height="38px"
      icon
      elevation="5"
      style="border-radius: 10px"
      ><v-img
        max-width="25px"
        :src="require('@/assets/img/changeLocation.svg')"
        :class="$store.state.settings.dark ? 'filter' : ''"
    /></v-btn>
  </v-row>
</template>

<script>
export default {
  name: "SearchAppBar",
  methods: {
    // Only allow search for logged in users
    goToSearch() {
      this.$router.safePush({
        name: "search",
        params: {
          searchBarClicked: true,
        },
      });
    },
  },
};
</script>
<style scoped>
/deep/ .mainSearchbar .v-input__slot {
  padding: 0 !important;
}
/deep/ .v-toolbar__content {
  padding: 8px 10px !important;
}
.match-background-color {
  background-color: var(--v-background-base) !important;
}
</style>
