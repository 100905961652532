var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"overflow":"hidden","overflow-x":"auto"}},_vm._l((_vm.items),function(item,i){return _c('div',{key:'cat-' + i,staticClass:"pt-2"},[(item.image && !_vm.$store.state.isMiKY)?_c('div',[_c('v-card',{staticClass:"mr-1 ml-1",staticStyle:{"border-radius":"10px !important","margin-bottom":"5px","background-size":"contain"},style:(_vm.$vuetify.breakpoint.xs
            ? 'margin-bottom:5px;'
            : 'margin-bottom:15px;'),attrs:{"elevation":"6","img":item.image,"height":_vm.$vuetify.breakpoint.xs ? '12vh' : '12vh',"width":_vm.$vuetify.breakpoint.mdAndUp ? '13vw' : '25vw'},on:{"click":function($event){return _vm.$router.safePush({
            name: 'byCategory',
            params: {
              id: item.id,
              type: _vm.type,
            },
          })}}}),_c('div',{staticClass:"Heading-Dark-H6-14-Center ml-1 mr-1 pb-2",staticStyle:{"word-break":"break-word","max-width":"25vw","white-space":"normal"}},[(_vm.type == 'category')?[_vm._v(" "+_vm._s(_vm.$t(("categories." + (item.title))))+" ")]:_vm._e(),(_vm.type == 'mood')?[_vm._v(" "+_vm._s(_vm.$t(("moods." + (item.title))))+" ")]:_vm._e()],2)],1):_vm._e(),(item.pictogram && _vm.$store.state.isMiKY)?_c('div',[_c('v-btn',{staticClass:"ml-2 mr-2 gradient",attrs:{"fab":"","depressed":""},on:{"click":function($event){return _vm.$router.safePush({
            name: 'byCategory',
            params: {
              id: item.id,
              type: _vm.type,
            },
          })}}},[_c('img',{staticClass:"filter",staticStyle:{"top":"2px","left":"2px"},attrs:{"width":"30px","height":"auto","src":item.pictogram}})]),_c('div',{staticClass:"Text-Dark-Text-4---12-Center pt-2 ml-1 pb-2",staticStyle:{"word-break":"break-word","max-width":"64px","white-space":"normal"}},[_vm._v(" "+_vm._s(_vm.$t(("categories." + (item.title))))+" ")])],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }