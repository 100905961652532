var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-container',{staticClass:"pa-0"},[_c('v-card',{staticStyle:{"border-radius":"20px !important"},attrs:{"img":_vm.item.thumbnail && _vm.item.thumbnail.url ? _vm.item.thumbnail.url : _vm.highResImage,"height":_vm.$vuetify.breakpoint.xs ? '150' : '200',"width":_vm.$vuetify.breakpoint.mdAndUp ? '35vw' : '75vw'},on:{"click":function($event){$event.stopPropagation();_vm.carousel
        ? _vm.$emit('carousel-clicked')
        : _vm.$router.safePush({
            name: 'detail',
            params: {
              id: _vm.item.id,
            },
          })}}},[_c('v-card-actions',[(_vm.item.specialDeals && !_vm.item.new && !_vm.item.insider)?_c('div',{staticClass:"card-promo white--text"},[_vm._v(" "+_vm._s(_vm.$t("detail.specialDeals"))+" ")]):_vm._e(),(_vm.item.new && !_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"flag-content-new Text-White-Text-4---12-Left-Align"},[_vm._v(_vm._s(_vm.$t("dashboard.new")))])],1):_vm._e(),(_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"Text-White-Text-4---12-Left-Align flag-content-insider"},[_vm._v(_vm._s(_vm.$t("dashboard.insider")))])],1):_vm._e(),_c('v-spacer'),(_vm.item.userRating && _vm.item.userRating > 0)?[_c('v-icon',{attrs:{"color":"yellow darken-2"}},[_vm._v(" mdi-star ")]),_c('span',{staticClass:"caption white--text"},[_vm._v(_vm._s(parseFloat(_vm.item.userRating).toFixed(1)))])]:_vm._e(),(
          _vm.$store.state.profile.data &&
          _vm.$store.state.session.user &&
          !_vm.$store.state.isMiKY
        )?_c('v-btn',{class:_vm.$store.state.settings.dark ? '' : 'white--text',attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(_vm.item.id)}}},[_c('v-icon',{attrs:{"color":_vm.find(_vm.$store.state.profile.data.favorites, function (fav) {
              return fav === _vm.item.id;
            })
              ? 'red'
              : 'white'}},[_vm._v(_vm._s(_vm.find(_vm.$store.state.profile.data.favorites, function (fav) { return fav === _vm.item.id; }) ? "mdi-heart" : "mdi-heart-outline"))])],1):_vm._e()],2)],1),_c('v-card',{staticClass:"mt-n8",staticStyle:{"border-radius":"15px !important","left":"2.5vw !important"},style:(_vm.$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''),attrs:{"elevation":"6","min-height":_vm.$vuetify.breakpoint.xs ? '100' : '120',"width":_vm.$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'},on:{"click":function($event){$event.stopPropagation();_vm.carousel
        ? _vm.$emit('carousel-clicked')
        : _vm.$router.safePush({
            name: 'detail',
            params: {
              id: _vm.item.id,
            },
          })}}},[(_vm.item.weatherSuitability)?_c('v-icon',{staticClass:"mt-2",staticStyle:{"width":"100%","position":"absolute"},attrs:{"icon":"","color":"yellow darken-2"}},[_vm._v("mdi-white-balance-sunny")]):_vm._e(),(_vm.item.category)?_c('v-card-actions',[(_vm.item.category.pictogram)?[_c('div',{staticClass:"mr-2 gradient pa-2 rounded-circle"},[_c('v-img',{staticClass:"filter",attrs:{"width":"20px","height":"auto","src":_vm.item.category.pictogram}})],1)]:_vm._e(),_c('span',{staticClass:"mt-1 Text-Dark-Text-3---14-Left-Align",class:!_vm.item.category.pictogram ? 'ml-2' : ''},[_vm._v(" "+_vm._s(_vm.$t(("categories." + (_vm.item.category.title))))+" ")]),_c('v-spacer'),(_vm.item.priceSegment)?_c('div',{staticClass:"mb-0",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.item.priceSegment)+" ")]):_vm._e()],2):_vm._e(),(_vm.item.header)?_c('v-card-title',{staticClass:"py-0",class:!_vm.item.category ? 'pt-4' : ''},[_c('div',{staticClass:"header Heading-Dark-H4-18-Left-Align"},[_vm._v(" "+_vm._s(_vm.item.header)+" ")])]):_vm._e(),_c('v-card-text',{staticClass:"Text-Dark-Text-3---14-Left-Align pb-2",staticStyle:{"word-break":"break-word"}},[_c('div',{staticClass:"subHeader"},[_vm._v(" "+_vm._s(_vm.item.subHeader)+" ")]),(_vm.openingHours)?_c('div',{staticClass:"v-html Text-Dark-Text-4---12-Left-Align mt-2",class:_vm.$store.state.settings.dark ? 'white--text' : 'black--text',domProps:{"innerHTML":_vm._s(_vm.openingHours)}}):_vm._e()])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }