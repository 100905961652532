var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.$vuetify.breakpoint.lgAndUp)?_c('div',[(_vm.isMiKY)?_c('v-btn',{staticStyle:{"background-color":"var(--v-background-base)"},attrs:{"fixed":"","top":"","left":"","elevation":"3","fab":"","icon":""},on:{"click":function($event){return _vm.$store.commit('SET_MENU', true)}}},[_c('img',{staticClass:"color-filter",attrs:{"src":require('@/assets/navigation/burger_icon.png'),"width":"30","height":"30"}})]):_vm._e()],1):_vm._e(),(_vm.isMiKY)?_c('div',{staticStyle:{"height":"64px"}}):_vm._e(),_c('greeting'),_c('search-app-bar'),[[_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center"}},[(
            (!_vm.$store.state.session.user && !_vm.$store.state.isMiKY) ||
            (_vm.filteredJustForYou && _vm.filteredJustForYou.length) ||
            _vm.loading
          )?_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.justForYou"))+" ")]):_vm._e(),_c('v-spacer'),(
            (_vm.$store.state.session.user || _vm.$store.state.isMiKY) &&
            _vm.filteredJustForYou.length
          )?_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
              name: 'allRecommendations',
              query: Object.assign({}, _vm.$router.currentRoute.query, {type: 'justForYou'}),
            })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))]):_vm._e()],1),(_vm.loading && (_vm.$store.state.session.user || _vm.$store.state.isMiKY))?_c('v-skeleton-loader',{staticStyle:{"border-radius":"8px"},attrs:{"width":"270","height":"200","type":"image"}}):_vm._e(),(!_vm.$store.state.session.user && !_vm.$store.state.isMiKY)?_c('v-lazy',[_c('image-slider')],1):_vm._e(),(!_vm.loading && (_vm.$store.state.session.user || _vm.$store.state.isMiKY))?_c('v-lazy',{attrs:{"transition":"fade-transition"}},[(_vm.filteredJustForYou.length)?_c('recommendation-slider',{attrs:{"filteredRecommendations":_vm.filteredJustForYou}}):_vm._e()],1):_vm._e()],(_vm.filteredCategories && _vm.filteredCategories.length)?[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.categories"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
              name: 'allCategories',
              query: Object.assign({}, _vm.$router.currentRoute.query, {type: 'category'}),
            })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))])],1),_c('filter-slider',{attrs:{"items":_vm.filteredCategories,"type":"category"}})]:_vm._e(),(_vm.filteredHighlights && _vm.filteredHighlights.length)?[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.highlights"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
              name: 'allRecommendations',
              query: Object.assign({}, _vm.$router.currentRoute.query, {type: 'highlights'}),
            })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))])],1),_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('item-slider',{attrs:{"filteredRecommendations":_vm.filteredHighlights}})],1)]:_vm._e(),[_c('div',[(
            _vm.goLocalItems && _vm.goLocalItems.items && _vm.goLocalItems.items.length
          )?_c('div',[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.go local"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
                  name: 'allRecommendations',
                  query: Object.assign({}, _vm.$router.currentRoute.query,
                    {type: 'byTag',
                    typeDetail: 'go local'}),
                })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))])],1),(_vm.goLocalLoading)?_c('v-skeleton-loader',{staticStyle:{"border-radius":"13px"},attrs:{"width":"270","height":"150","type":"image"}}):_vm._e(),(_vm.goLocalLoading)?_c('v-card',{staticClass:"mt-n8 pt-1",staticStyle:{"border-radius":"15px !important","left":"2.5vw !important"},style:(_vm.$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''),attrs:{"elevation":"6","min-height":_vm.$vuetify.breakpoint.xs ? '100' : '120',"width":_vm.$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'}},[_c('v-skeleton-loader',{attrs:{"width":"234","type":"list-item-avatar-two-line"}})],1):_vm._e(),(!_vm.goLocalLoading)?_c('v-lazy',{staticStyle:{"margin-bottom":"env(safe-area-inset-bottom)"},attrs:{"transition":"fade-transition"}},[_c('item-slider',{attrs:{"filteredRecommendations":_vm.goLocalItems.items.slice(0, 10)}})],1):_vm._e()],1):_vm._e()])],(
        (_vm.filteredRecommendations && _vm.filteredRecommendations.length) || _vm.loading
      )?[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.recommendations"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
              name: 'allRecommendations',
              query: Object.assign({}, _vm.$router.currentRoute.query,
                {type: 'recommendations'}),
            })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))])],1),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"border-radius":"13px"},attrs:{"width":"270","height":"150","type":"image"}}):_vm._e(),(_vm.loading)?_c('v-card',{staticClass:"mt-n8 pt-1",staticStyle:{"border-radius":"15px !important","left":"2.5vw !important"},style:(_vm.$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''),attrs:{"elevation":"6","min-height":_vm.$vuetify.breakpoint.xs ? '100' : '120',"width":_vm.$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'}},[_c('v-skeleton-loader',{attrs:{"width":"234","type":"list-item-avatar-two-line"}})],1):_vm._e(),(!_vm.loading)?_c('v-lazy',{attrs:{"transition":"fade-transition"}},[_c('item-slider',{attrs:{"filteredRecommendations":_vm.filteredRecommendations}})],1):_vm._e()]:_vm._e(),(_vm.filteredPopular && _vm.filteredPopular.length > 0 && !_vm.isMiKY)?[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.popular"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
              name: 'allRecommendations',
              query: Object.assign({}, _vm.$router.currentRoute.query, {type: 'popular'}),
            })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))])],1),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"border-radius":"13px"},attrs:{"width":"270","height":"150","type":"image"}}):_vm._e(),(_vm.loading)?_c('v-card',{staticClass:"mt-n8 pt-1",staticStyle:{"border-radius":"15px !important","left":"2.5vw !important"},style:(_vm.$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''),attrs:{"elevation":"6","min-height":_vm.$vuetify.breakpoint.xs ? '100' : '120',"width":_vm.$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'}},[_c('v-skeleton-loader',{attrs:{"width":"234","type":"list-item-avatar-two-line"}})],1):_vm._e(),(!_vm.loading)?_c('v-lazy',{staticStyle:{"margin-bottom":"env(safe-area-inset-bottom)"},attrs:{"transition":"fade-transition"}},[_c('item-slider',{attrs:{"filteredRecommendations":_vm.filteredPopular}})],1):_vm._e()]:_vm._e(),(_vm.filteredAttractions && _vm.filteredAttractions.length)?[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.attractions"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
              name: 'allRecommendations',
              query: Object.assign({}, _vm.$router.currentRoute.query, {type: 'attractions'}),
            })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))])],1),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"border-radius":"13px"},attrs:{"width":"270","height":"150","type":"image"}}):_vm._e(),(_vm.loading)?_c('v-card',{staticClass:"mt-n8 pt-1",staticStyle:{"border-radius":"15px !important","left":"2.5vw !important"},style:(_vm.$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''),attrs:{"elevation":"6","min-height":_vm.$vuetify.breakpoint.xs ? '100' : '120',"width":_vm.$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'}},[_c('v-skeleton-loader',{attrs:{"width":"234","type":"list-item-avatar-two-line"}})],1):_vm._e(),(!_vm.loading)?_c('v-lazy',{staticStyle:{"margin-bottom":"env(safe-area-inset-bottom)"},attrs:{"transition":"fade-transition"}},[_c('item-slider',{attrs:{"filteredRecommendations":_vm.filteredAttractions}})],1):_vm._e()]:_vm._e(),_vm._l((_vm.tags),function(tag,index){return [_c('div',{key:'tag-' + index},[(
            _vm.tagItems[index] &&
            _vm.tagItems[index].items &&
            _vm.tagItems[index].items.length > 0
          )?_c('div',[_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('h2',{staticClass:"ml-4 HeadingDarkH3-22Left-Align"},[_vm._v(" "+_vm._s(_vm.$t("dashboard." + tag[0]))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"Text-Color-Text-3---14-Left-Align",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.safePush({
                  name: 'allRecommendations',
                  query: Object.assign({}, _vm.$router.currentRoute.query,
                    {type: 'byTag',
                    typeDetail: tag}),
                })}}},[_vm._v(_vm._s(_vm.$t("dashboard.seeAll")))])],1),(_vm.tagLoading[index])?_c('v-skeleton-loader',{staticStyle:{"border-radius":"13px"},attrs:{"width":"270","height":"150","type":"image"}}):_vm._e(),(_vm.tagLoading[index])?_c('v-card',{staticClass:"mt-n8 pt-1",staticStyle:{"border-radius":"15px !important","left":"2.5vw !important"},style:(_vm.$vuetify.breakpoint.xs ? 'left: 5vw !important' : ''),attrs:{"elevation":"6","min-height":_vm.$vuetify.breakpoint.xs ? '100' : '120',"width":_vm.$vuetify.breakpoint.mdAndUp ? '30vw' : '65vw'}},[_c('v-skeleton-loader',{attrs:{"width":"234","type":"list-item-avatar-two-line"}})],1):_vm._e(),(!_vm.tagLoading[index])?_c('v-lazy',{staticStyle:{"margin-bottom":"env(safe-area-inset-bottom)"},attrs:{"transition":"fade-transition"}},[_c('item-slider',{attrs:{"filteredRecommendations":_vm.tagItems[index].items.slice(0, 10)}})],1):_vm._e()],1):_vm._e()])]}),_c('v-bottom-sheet',{model:{value:(_vm.addressPicker),callback:function ($$v) {_vm.addressPicker=$$v},expression:"addressPicker"}},[_c('v-sheet',{staticClass:"text-center"},[_c('address-picker')],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }