<template>
  <v-layout style="overflow: hidden; overflow-x: auto">
    <div v-for="(item, i) in items" :key="'cat-' + i" class="pt-2">
      <div v-if="item.image && !$store.state.isMiKY">
        <v-card
          elevation="6"
          :img="item.image"
          class="mr-1 ml-1"
          :height="$vuetify.breakpoint.xs ? '12vh' : '12vh'"
          :width="$vuetify.breakpoint.mdAndUp ? '13vw' : '25vw'"
          style="
            border-radius: 10px !important;
            margin-bottom: 5px;
            background-size: contain;
          "
          :style="
            $vuetify.breakpoint.xs
              ? 'margin-bottom:5px;'
              : 'margin-bottom:15px;'
          "
          @click="
            $router.safePush({
              name: 'byCategory',
              params: {
                id: item.id,
                type,
              },
            })
          "
        >
        </v-card>
        <div
          style="word-break: break-word; max-width: 25vw; white-space: normal"
          class="Heading-Dark-H6-14-Center ml-1 mr-1 pb-2"
        >
          <template v-if="type == 'category'">
            {{ $t(`categories.${item.title}`) }}
          </template>
          <template v-if="type == 'mood'">
            {{ $t(`moods.${item.title}`) }}
          </template>
        </div>
      </div>
      <div v-if="item.pictogram && $store.state.isMiKY">
        <v-btn
          class="ml-2 mr-2 gradient"
          fab
          depressed
          @click="
            $router.safePush({
              name: 'byCategory',
              params: {
                id: item.id,
                type,
              },
            })
          "
        >
          <img
            class="filter"
            width="30px"
            height="auto"
            :src="item.pictogram"
            style="top: 2px; left: 2px"
          />
        </v-btn>
        <div
          style="word-break: break-word; max-width: 64px; white-space: normal"
          class="Text-Dark-Text-4---12-Center pt-2 ml-1 pb-2"
        >
          {{ $t(`categories.${item.title}`) }}
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: ["items", "type"],
};
</script>
