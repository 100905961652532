<template>
  <v-layout style="overflow: hidden; overflow-x: auto">
    <v-flex v-for="(item, i) in filteredRecommendations" :key="i">
      <div :width="$vuetify.breakpoint.xs ? '75vw' : '55vw'" class="mr-4 pb-3">
        <v-lazy transition="fade-transition">
          <item-slider-recommendation :item="item"></item-slider-recommendation>
        </v-lazy>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import ItemSliderRecommendation from "@/components/recommendations/ItemSliderRecommendation";
export default {
  data: () => ({}),
  components: {
    ItemSliderRecommendation,
  },
  props: ["filteredRecommendations"],
};
</script>
